<template>
  <div v-if="isSuccess">
    <b-row>
      <b-col md="10" class="mb-1 font-weight-bold">Document Types</b-col>
      <b-col md="2" class="d-flex justify-content-end">
        <b-button v-ripple variant="primary" @click="addTypeHandler">
          Add Type
        </b-button>
      </b-col>
    </b-row>

    <b-row
      v-for="(type, i) in settingsModel.types"
      :key="i + '_' + settingsModel.types.length"
      class="align-items-center"
    >
      <b-col md="3">
        <FormInputGroup v-model="type.name" label="Name" />
      </b-col>
      <b-col md="9" class="d-flex align-items-center">
        <b-button
          v-ripple
          variant="danger"
          class="mr-2"
          @click="removeTypeHandler(i)"
        >
          X
        </b-button>
        <b-button v-ripple variant="primary" @click="addFieldHandler(type)">
          Add Field
        </b-button>
      </b-col>

      <b-col
        v-for="(field, i) in type.fields"
        :key="i + '_' + type.fields.length"
        md="12"
      >
        <b-row class="mb-2 align-items-center">
          <b-col md="3">
            <FormInputGroup v-model="field.name" label="Name" />
          </b-col>
          <b-col md="3">
            <FormInputGroup v-model="field.label" label="Label" />
          </b-col>
          <b-col md="3">
            <FormInputGroup
              v-model="optionModel"
              :disabled="field.type !== 'select'"
              :label="$t('forms.labels.addOption')"
              is-text
            >
              <template #append>
                <feather-icon
                  icon="PlusIcon"
                  class="cursor-pointer"
                  @click="addOptionHandler(field)"
                />
              </template>
            </FormInputGroup>
            <ul>
              <li v-for="option in field.options" :key="option.label">
                {{ option.label }}
              </li>
            </ul>
          </b-col>
          <b-col md="3">
            <FormSelectGroup
              :value.sync="field.type"
              :options="typesField"
              :label="$t('forms.labels.selectDate')"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12" class="mt-50">
        <b-button v-ripple variant="primary" class="mr-1" @click="update">
          {{ $t('forms.buttons.save') }}
        </b-button>
        <b-button v-ripple variant="outline-secondary" @click="$router.go(-1)">
          {{ $t('forms.buttons.cancel') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api'
import { useSettingsQuery } from '@/store/settings'
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import {
  FormInputGroup,
  FormImageGroup,
  FormSelectGroup,
} from '@/views/components/forms'
import useToast from '@/views/components/useToast'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    FormInputGroup,
    FormImageGroup,
    FormSelectGroup,
  },
  setup() {
    const toast = useToast()
    let settingsModel = reactive({
      id: null,
      types: [],
    })

    const optionModel = ref('')
    const updater = ref(0)

    const typesField = [
      { value: 'input', label: 'Input' },
      { value: 'select', label: 'Select' },
    ]

    const addFieldHandler = type => {
      settingsModel.types = settingsModel.types.map(t => {
        return t.name === type.name
          ? {
              ...t,
              fields: [
                ...t.fields,
                { type: 'input', name: '', label: '', options: [] },
              ],
            }
          : { ...t }
      })
    }

    const removeTypeHandler = index => {
      settingsModel.types = settingsModel.types.filter((t, i) => i !== index)
    }

    const addTypeHandler = () => {
      settingsModel.types.push({
        name: '',
        fields: [],
      })
    }

    const addOptionHandler = field => {
      field.options.push({
        value: field.options.length || 0,
        label: optionModel.value,
      })
      optionModel.value = ''
    }

    const { isSuccess } = useSettingsQuery.detail({
      options: {
        onSuccess(data) {
          if (data.success) {
            settingsModel = Object.assign(settingsModel, data.settings)
          }
        },
        refetchOnMount: 'always',
      },
    })

    const { mutate: update } = useSettingsQuery.update({
      settings: settingsModel,
      options: {
        onSuccess(data) {
          if (data.success) {
            settingsModel = Object.assign(settingsModel, data.settings)
            toast.success(JSON.stringify(data.settings))
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      },
    })

    return {
      settingsModel,
      typesField,
      optionModel,
      updater,
      update,
      isSuccess,
      addOptionHandler,
      addTypeHandler,
      addFieldHandler,
      removeTypeHandler,
    }
  },
}
</script>
